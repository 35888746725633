import React from 'react';
import styled from 'styled-components';
import {Form, Input, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as AppContext from '../AppContext';
import * as Widget from '../components/Widget';

function CreateAdminUserPage(props) {
  const [actions] = useOutlet('actions');

  const onFinish = async (values) => {
    actions.setLoading(true);
    try {
      await actions.createAdminuser(values);
      console.log('Success:', values);
      message.success(`創建成功！`);
    } catch (err) {
      console.warn(err);
    } finally {
      actions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.warn('Failed:', errorInfo);
    message.error(`創建失敗! ${JSON.stringify(errorInfo)}`);
  };

  return (
    <Wrapper>
      <Widget.MaxWidthCenter>
        <h2>Create Admin User</h2>
      </Widget.MaxWidthCenter>

      <Widget.MaxWidthCenter>
        <Form
          name="basic"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          initialValues={{username: '', password: ''}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label="username"
            name="username"
            rules={[{required: true, message: 'Please input your username!'}]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="password"
            name="password"
            rules={[{required: true, message: 'Please input your password!'}]}>
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Widget.MaxWidthCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  min-height: 100vh;
`;

export default CreateAdminUserPage;
